<template>
  <image-content show-logo show-footer>
    <template #image-headline>
      <h1
        v-if="configuration.showImageHeadline"
        class="Headline is-size-4"
        v-t="'BasicRegistrationTitle'"
      />
    </template>

    <template #image>
        <figure>
          <img
            v-if="configuration.name==='default'"
            src="@/assets/images/avatar-recipient.png"
            :alt="$t('userRoles.recipient')"
          />
          <img
            :id="configuration.imageId"
            v-if="configuration.name !== 'default'"
            :src="configuration.image"
            :alt="$t('userRoles.recipient')"
          />
        </figure>
    </template>

    <template #image-below>
    </template>

    <template #content>
      <div id="RegisterForm" class="Content">
        <regapp-feedback v-if="registered">
          <template #body>
                <div v-html="translateWithMymedelString(`${responseMessageKey}.basic`)" />
          </template>

          <template #footer>
            <button
              v-if="configuration.linkType==='route'"
              class="ButtonLink"
              @click="openLogin"
              >
              {{ $t(configuration.linkLabel) }}
            </button>

            <a
              v-if="configuration.linkType !== 'route'"
              :href="configuration.linkTarget"
              class="Link d-i-block text-grey mt-1"
            >
              {{ $t(configuration.linkLabel) }}
            </a>
          </template>
        </regapp-feedback>

        <Form v-else @submit="submitBasicRegistration" v-slot="errors" class="input-fields">
                <strong
                  class="Headline is-size-5 d-block"
                  v-html="translateWithMymedelString(configuration.formText)"
                />
                <input-component :fieldName="fieldNames.email" :fieldLabel="emailFieldLabel" :rules="`required|email`" type="email" :errors="errors"/>
                <input-password-component :fieldName="fieldNames.password" :fieldLabel="passwordFieldLabel" :rules="`required|passwordMasterRule:@${fieldNames.email}`" :errors="errors"/>
                <input-component :fieldName="fieldNames.passwordConf" :fieldLabel="passwordConfirmationFieldLabel" :rules="`required|passwordConfirm:@${fieldNames.password}`" type="password" :errors="errors"/>
                <input-component :fieldName="fieldNames.firstName" :fieldLabel="firstNameFieldLabel" :rules="`required|max:20`" type="text" :errors="errors"/>
                <input-component :fieldName="fieldNames.lastName" :fieldLabel="lastNameFieldLabel" :rules="`required|max:20`" type="text" :errors="errors"/>
                <input-select-component :fieldName="fieldNames.country" :options="countries" :fieldLabel="countryFieldLabel" />
                <input-checkbox-component
                    :fieldLabel="$t('form.terms')"
                    :errors="errors.errors[fieldNames.terms]"
                    :fieldName="fieldNames.terms">
                    <terms-of-use-and-privacy-policy-links />
                </input-checkbox-component>
              <button
              class="Button form-button d-block"
              :class="{ 'is-grey': !errors.meta.valid }"
            >
              <span v-show="!isCheckingMail" v-text="$t(nextLabel)" />
              <simple-spinner
                :show="isCheckingMail"
                classNames="is-registration"
              />
            </button>
        </Form>
        <p>
          {{ $t('form.alreadyAccount') }}
          <button class="ButtonLink" @click="openLogin">{{ $t('form.loginNow') }}</button>
        </p>

        <validation-error-message :errors="errors" ref="validationErrorMessage" />

        <sweet-modal icon="warning" ref="modalError">
          <div v-html="$t('registration.messages.internal:error')" />
        </sweet-modal>
      </div>
    </template>
  </image-content>
</template>

<script>
import registrationSteps from '@/mixins/registrationForm.js'
import ValidationErrorMessage from '../components/ValidationErrorMessage.vue'
import localeMessages from '@/mixins/localeMessages'
import TermsOfUseAndPrivacyPolicyLinks from '../components/partials/TermsOfUseAndPrivacyPolicyLinks.vue'
import { appSettings } from '@/settings'
import { Form } from 'vee-validate'
import InputComponent from '@/components/partials/InputComponent.vue'
import InputCheckboxComponent from '@/components/partials/InputCheckboxComponent.vue'
import InputPasswordComponent from '@/components/partials/InputPasswordComponent.vue'
import InputSelectComponent from '@/components/partials/InputSelectComponent.vue'

export default {
  name: 'RegisterBasic',

  metaInfo () {
    return {
      title: this.$i18n.t('pages.recipientRegistration.meta.title')
    }
  },

  mixins: [ registrationSteps, localeMessages ],

  components: {
    ValidationErrorMessage,
    TermsOfUseAndPrivacyPolicyLinks,
    Form,
    InputComponent,
    InputPasswordComponent,
    InputSelectComponent,
    InputCheckboxComponent
  },

  mounted () {
    this.scrollUp()
  },

  computed: {
    benefits () {
      return this.$tm('registration.recipient.benefits')
    },

    configuration () {
      const brand = (this.$route.query && this.$route.query.brand) ? this.$route.query.brand : 'default'

      const brandSettings = {
        'default': {
          name: 'default',
          showImageHeadline: true,
          formText: 'registration.basic.title',
          linkLabel: 'links.toLogin.label',
          linkType: 'route',
          linkTarget: 'home',
          userType: '' // no user type given for the basic signup
        },
        'hearbetter': {
          name: 'hearBetter',
          showImageHeadline: false,
          formText: 'registration.hearBetter.text',
          linkLabel: 'registration.hearBetter.linkLabel',
          linkType: 'link',
          linkTarget: appSettings.hearBetter ? appSettings.hearBetter.successUrl : '',
          userType: 'Professional',
          image: require('@/assets/images/Hear-Better-Logo_Corp-font-RGB-pos.svg'),
          imageId: 'hearBetterImage'
        },
        'hearpeers': {
          name: 'hearpeers',
          showImageHeadline: false,
          formText: 'registration.basic.title',
          linkLabel: 'links.toLogin.label',
          linkType: (appSettings.hearpeers && appSettings.hearpeers.linkType) ? appSettings.hearpeers.linkType : 'route',
          linkTarget: appSettings.hearpeers ? appSettings.hearpeers.successUrl : 'home',
          userType: '',
          image: require('@/assets/images/hearpeers_logo.png'),
          imageId: 'hearpeersImage'
        }
      }

      if (brandSettings[brand]) {
        return brandSettings[brand]
      }

      return brandSettings['default']
    }
  },

  data () {
    return {
      errors: {},
      country: null,
      registered: false,
      hasError: false,
      isCheckingMail: false,
      fullRegistration: false,
      responseMessageKey: '',
      formTransition: 'form-step-left',
      fieldNames: {
        email: 'email',
        password: 'password',
        passwordConf: 'passwordConf',
        firstName: 'firstName',
        lastName: 'lastName',
        country: 'country',
        terms: 'terms'
      }
    }
  },

  methods: {
    getRequestObject (values) {
      return {
        email: values[this.fieldNames.email]?.trim(),
        firstName: values[this.fieldNames.firstName]?.trim(),
        lastName: values[this.fieldNames.lastName]?.trim(),
        password: values[this.fieldNames.password],
        userType: this.configuration.userType,
        language: this.$i18n.locale ? this.$i18n.locale : 'en',
        country: values[this.fieldNames.country].code,
        agreedToTermsAndConditions: values[this.fieldNames.terms],
        dateOfRegistration: new Date(),
        signupUrl: this.getSignupUrl()
      }
    },

    async submitBasicRegistration (values) {
        let payload = this.getRequestObject(values)
        let success = await this.createUserProfile(payload)
        if (success) {
          this.responseMessageKey = `registration.messages.success`
          this.registered = true
          this.scrollUp()
        }
    }
  }
}
</script>
<style scoped>
  #hearBetterImage{
    width: 75%;
    padding-bottom: 35%;
    padding-left: 5%;
  }

  #hearpeersImage{
    padding-left: -15%;
    padding-bottom: 10%;
  }
</style>
